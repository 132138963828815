import {Controller} from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

// Connects to data-controller="layouts--header-menu-mobile"
export default class extends Controller {
  static targets = ['navContainer']

  connect() {
    this.events()
  }

  openMenu() {
    if (!this.navContainerTarget) return

    this.navContainerTarget.classList.add('active')
    this.element.classList.add('active')
    disableBodyScroll(this.navContainerTarget)
  }

  closeMenu() {
    if (!(this.navContainerTarget.classList.contains('active') && this.element.classList.contains('active'))) return

    this.navContainerTarget.classList.remove('active')
    this.element.classList.remove('active')
    enableBodyScroll(this.navContainerTarget)
  }

  resizing() {
    this.closeMenu()
  }

  clickOutsideMenu() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerTypeAround = that.navContainerTarget.contains(event.target),
        containerTypeVisible = window.getComputedStyle(that.navContainerTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      !containerTypeAround && containerTypeVisible && leftClicked && that.closeMenu()
    })
  }

  events() {
    this.clickOutsideMenu()
  }
}
