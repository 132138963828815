import { Controller } from "@hotwired/stimulus"
import 'waypoints'
import 'waypoints-inview'

let resizeTimer = null

// Connects to data-controller="products--inquiries--form-guideline"
export default class extends Controller {
  static targets = ['container']

  connect() {
    this.load()
  }

  resizing() {
    this.load()
    this.resize()
  }

  load() {
    this.element.style.height = this.hasContainerTarget ? `${this.containerTarget.clientHeight}px` : null
    this.inview()
  }

  inview() {
    const that = this

    if (!this.hasContainerTarget) return

    new Waypoint.Inview({
      element: this.element,
      enter: function() {
        setTimeout(function() {
          that.element.classList.remove('hidden')
        }, 600)
      }
    })
  }

  close() {
    const that = this

    that.element.classList.add('hidden')
    setTimeout(function() {
      that.element.remove()
    }, 600)
  }

  resize() {
    const that = this

    that.element.classList.add('resizing')
    clearTimeout(resizeTimer)
    resizeTimer = setTimeout(function() {
      that.element.classList.remove('resizing')
    }, 300)
  }
}
