import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users--inputs--switch"
export default class extends Controller {
  static classes = ['state']
  static targets = ['checkbox']

  connect() {
    this.toggle();
  }

  toggle() {
    this.element.classList.toggle(this.stateClass, this.checkboxTarget.checked)
  }
}
