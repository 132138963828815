import { Controller } from "@hotwired/stimulus"
import ScrollBooster from 'scrollbooster';

// Connects to data-controller="home"
export default class extends Controller {
  static targets = ['controlViewport']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.scrollBooster()
  }

  scrollBooster() {
    this.controlViewportTargets.forEach((element) => {
      new ScrollBooster({
        viewport: element,
        content: element.querySelector('.list'),
        direction: 'horizontal',
        scrollMode: 'transform'
      });
    })
  }
}
