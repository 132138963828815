import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="users--profile--modal"
export default class extends Controller {
  static targets = ['modal']

  open(event) {
    event.preventDefault()

    new Modal(this.modalTarget).show()
  }

  close() {
    Modal.getInstance(this.modalTarget).hide()
  }
}
