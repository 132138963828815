import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="base"
export default class extends Controller {
  initialize() {
    this.loaded()
  }

  loaded() {
    this.correctFacebookRedirectUri()
  }

  correctFacebookRedirectUri() {
    window.location.href.indexOf('#_=_') > 0 && (window.location = window.location.href.replace(/#.*/, ''));
  }
}
