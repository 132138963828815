import { Controller } from '@hotwired/stimulus'
import LazyLoad from 'vanilla-lazyload'
import SmoothScroll from 'smooth-scroll'

// Connects to data-controller="layouts"
export default class extends Controller {
  initialize() {
    this.loaded()
  }

  loaded() {
    this.lazyLoaded()
    this.smoothScroll()
    this.smoothScrollAuto()
  }

  lazyLoaded() {
    new LazyLoad({
      elements_selector: '.lazy',
      callback_loaded: function(element) {
        const lazyLoading = element.closest('.lazy-loading')
        element.classList.remove('lazy')
        element.removeAttribute('data-src')
        element.removeAttribute('class')
        lazyLoading  && lazyLoading .classList.remove('lazy-loading')
      }
    })
  }

  smoothScroll() {
    new SmoothScroll('a[href*="#"]', {
      header: 'header nav .container',
      speed: 500,
      speedAsDuration: true,
      updateURL: false
    })
  }

  smoothScrollAuto() {
    if (window.location.href.indexOf('#_=_') > 0) return

    const target = location.hash && document.querySelector(`#content ${location.hash}`)

    target && setTimeout(function() {
      new SmoothScroll().animateScroll(target, null, {
        header: 'header nav .container',
        speed: 500,
        speedAsDuration: true,
        updateURL: false
      })
    }, 900)
  }
}
