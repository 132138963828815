import { Controller } from '@hotwired/stimulus'
import { tns } from 'tiny-slider'

let resizeTimer = null,
  windowWidth = window.innerWidth

// Connects to data-controller="home--banner-sliders"
export default class extends Controller {
  static targets = ['containerLoading', 'containerSlider']

  connect() {
    this.configSlider()
  }

  resizing() {
    this.resizeSlider()
  }

  configSlider() {
    var slider = tns({
      container: '.tiny-slider',
      onInit: this.initializeSlider(),
      items: 1,
      swipeAngle: false,
      speed: 400,
      autoplay: true,
      autoplayHoverPause: true,
      autoplayButtonOutput: false,
      navPosition: 'bottom',
      controls: false,
      mouseDrag: true,
    })

    slider.events.on('touchstart', function() {
      slider.pause()
    })

    slider.events.on('dragMove', function() {
      slider.pause()
    })

    slider.events.on('dragEnd', function() {
      slider.pause()
    })

    slider.play()
  }

  initializeSlider() {
    this.containerLoadingTarget.classList.remove('loading')
  }

  blurSlider(state) {
    this.containerSliderTarget.classList.toggle('resizing', state)
  }

  resizeSlider() {
    if (windowWidth === window.innerWidth) return

    this.blurSlider(true)
    clearTimeout(resizeTimer)

    resizeTimer = setTimeout(() => {
      this.blurSlider(false)
    }, 300)

    windowWidth = window.innerWidth
  }
}
