import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="users--inputs--select"
export default class extends Controller {
  static targets = ['select']

  connect() {
    this.selectOptions()
  }

  selectOptions() {
    this.selectTargets.forEach((element) => {
      new SlimSelect({
        select: element,
        showSearch: false
      })
    })
  }
}
