import { Controller } from "@hotwired/stimulus"
import Ripple from 'ripple-effects';

let colors = {
  redTorch: '#f20f38'
}

// Connects to data-controller="products--tabs"
export default class extends Controller {
  static targets = ['controlItem', 'containerItem']

  resizing() {
    this.resize()
  }

  click(event) {
    event.preventDefault()

    Ripple(event.target, { background: colors.redTorch, opacity: 0.1 })

    const ActiveTabClassName = [].slice.apply(event.target.closest('li').classList).pop()

    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })

    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height)

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  resize() {
    this.containerItemTargets.forEach((element) => {
      element.classList.contains('active') && (element.style.height = 'auto')
    })
  }
}
