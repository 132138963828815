import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

// Connects to data-controller="users--profile--modal-confirmation"
export default class extends Controller {
  static targets = ['modal', 'form']

  open(event) {
    event.preventDefault()

    new Modal(this.modalTarget).show()
  }

  submit() {
    if (this.formTarget.checkValidity() || this.formTarget.reportValidity()) {
      this.formTarget.submit()
    } else {
      Modal.getInstance(this.modalTarget).hide()
    }
  }

  close(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).hide()
  }
}
