import { Controller } from '@hotwired/stimulus'
import autoComplete from '@tarekraafat/autocomplete.js'
import { sprintf } from 'sprintf-js'

// Connects to data-controller="layouts--header-search-mobile"
export default class extends Controller {
  static targets = ['type', 'typeTitle', 'typeList', 'typeInnerList', 'form', 'formInner', 'inputFreeword']

  initialize() {
    this.loaded()
  }

  connect() {
    this.events()
  }

  autoComplete() {
    const
      that = this,
      autoCompleteMobile = new autoComplete({
        selector: () => {
          return that.inputFreewordTarget
        },
        data: {
          src: async () => {
            const
              typeCheckedValue = document.querySelector('input[name^=search_mobile_type]:checked').value,
              params = new URLSearchParams({
                type: typeCheckedValue,
                freeword: that.inputFreewordTarget.value
              }).toString(),
              source = await fetch(`${searchAutocompleteUrl}?${params}`),
              data = await source.json()

            return data.freewords
          },
          cache: false,
        },
        resultsList: {
          element: (list, data) => {
            const info = document.createElement('p')

            if (data.results.length < 1) {
              info.innerHTML = sprintf(searchAutocompleteTranslations.results_html, data.matches.length, data.query)
              info.classList.add('active')
            } else {
              info.classList.contains('active') && info.classList.remove('active')
            }

            list.prepend(info)
          },
          noResults: true,
          maxResults: 10
        },
        resultItem: {
          highlight: true
        },
        events: {
          input: {
            focus: () => {
              this.formTarget.classList.add('visible')
              autoCompleteMobile.input.value.length && autoCompleteMobile.start()
            },
            focusout: () => {
              this.formTarget.classList.remove('visible')
            },
            keydown: (event) => {
              switch (event.key) {
                case 'Enter':
                  return

                case 'ArrowRight':
                  autoCompleteMobile.select()
                  break

                case 'ArrowUp':
                  autoCompleteMobile.previous()
                  break

                case 'ArrowDown':
                  autoCompleteMobile.next()
                  break
              }
            }
          }
        }
      })

    that.inputFreewordTarget.addEventListener('selection', function(event) {
      that.inputFreewordTarget.value = event.detail.selection.value
    })
  }

  toggleForm() {
    const formInnerHeight = `${this.formInnerTarget.clientHeight}px`

    this.element.classList.toggle('active')
    this.formTarget.style.height = this.element.classList.contains('active') ? formInnerHeight : null
  }

  closeForm() {
    this.element.classList.remove('active')
    this.formTarget.style.height = null
    this.formTarget.classList.remove('visible')
  }

  toggleType() {
    this.typeTarget.classList.toggle('active')
    this.formTarget.classList.add('visible')

    this.typeListTarget.style.height =
      this.typeTarget.classList.contains('active') ? `${this.typeInnerListTarget.clientHeight}px` : null
  }

  clickType(event) {
    if (!event.target.control) return

    this.setTypeTitle(event.target.control.value)
    this.closeType()
  }

  closeType() {
    this.typeTarget.classList.remove('active')
    this.typeListTarget.style.height = null
  }

  setTypeTitle(value) {
    const checkedValue = value || document.querySelector('input[name^=search_mobile_type]:checked').value
    this.typeTitleTarget.textContent = searchTypeTranslations[checkedValue]
    this.formInnerTarget.action = searchPaths[checkedValue]
    this.inputFreewordTarget.placeholder = searchPlaceholderTranslations[checkedValue]
  }

  clickOutside() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerFormAround = that.element.contains(event.target),
        containerTypeAround = that.typeTarget.contains(event.target),
        containerFormVisible = window.getComputedStyle(that.element).visibility !== 'hidden',
        containerTypeVisible = window.getComputedStyle(that.typeTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      if (!leftClicked) return

      !containerFormAround && containerFormVisible && that.closeForm()
      !containerTypeAround && containerTypeVisible && that.closeType()
    })
  }

  loaded() {
    this.setTypeTitle()
  }

  events() {
    this.clickOutside()
    this.autoComplete()
  }
}
