import { Controller } from "@hotwired/stimulus"
import ScrollBooster from 'scrollbooster'

// Connects to data-controller="home--tabs"
export default class extends Controller {
  static targets = ['controlItem', 'containerItem']

  resizing() {
    this.resize()
  }

  click(event) {
    event.preventDefault()

    const ActiveTabClassName = [].slice.apply(event.target.closest('li').classList).pop()

    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })

    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        const containerItemHeight =
          element.querySelector('.div.desktop').clientHeight || element.querySelector('.div.mobile').clientHeight

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  resize() {
    this.containerItemTargets.forEach((element) => {
      element.classList.contains('active') && (element.style.height = 'auto')
    })
  }
}
