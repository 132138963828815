import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="categories--filters-mobile"
export default class extends Controller {
  static targets = ['options', 'optionList', 'optionInnerList']

  connect() {
    this.events()
  }

  toggleOptions() {
    this.optionsTarget.classList.toggle('active')
    this.optionListTarget.style.height =
      this.optionsTarget.classList.contains('active') ? `${this.optionInnerListTarget.clientHeight}px` : null
  }

  closeType() {
    this.optionsTarget.classList.remove('active')
    this.optionListTarget.style.height = null
  }

  clickOutsideType() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerTypeAround = that.optionsTarget.contains(event.target),
        containerTypeVisible = window.getComputedStyle(that.optionsTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      !containerTypeAround && containerTypeVisible && leftClicked && that.closeType()
    })
  }

  events() {
    this.clickOutsideType()
  }

}
