import { Controller } from "@hotwired/stimulus"
import Ripple from 'ripple-effects';
import ScrollBooster from 'scrollbooster';

let colors = {
  redTorch: '#f20f38'
}

// Connects to data-controller="users--profile--tabs"
export default class extends Controller {
  static targets = ['navigationItem', 'containerItem', 'navigationViewport', 'navigationList']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.load()
    this.scrollBooster()
  }

  load() {
    const profileClassName = document.querySelector('#profile').className

    if (profileClassName === 'index') return

    this.toggleNavigation(profileClassName)
    this.toggleContainer(profileClassName)
  }

  resizing() {
    this.resize()
  }

  toggleNavigation(className) {
    this.navigationItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })
  }

  toggleContainer(className) {
    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        const containerItemHeight = element.querySelector('.div').clientHeight

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  scrollBooster() {
    new ScrollBooster({
      viewport: this.navigationViewportTarget,
      content: this.navigationListTarget,
      direction: 'horizontal',
      scrollMode: 'transform'
    });
  }

  click(event) {
    event.preventDefault()

    Ripple(event.target, { background: colors.redTorch, opacity: 0.1 })

    const ActiveTabClassName = [].slice.apply(event.target.closest('li').classList).pop()

    this.toggleNavigation(ActiveTabClassName)
    this.toggleContainer(ActiveTabClassName)
  }

  resize() {
    this.containerItemTargets.forEach((element) => {
      element.classList.contains('active') && (element.style.height = 'auto')
    })
  }
}
