import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users--inputs--password"
export default class extends Controller {
  static targets = ['input', 'toggle', 'toggleIcon']

  toggle() {
    this.toggleIconTarget.classList.toggle('fa-eye-slash')
    this.inputTarget.type = this.toggleIconTarget.classList.contains('fa-eye-slash') ? 'text' : 'password'
  }

  iconToggle() {
    this.toggleTarget.classList.toggle('active', this.inputTarget.value.length > 0)
  }
}
