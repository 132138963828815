import { Controller } from '@hotwired/stimulus'
import autoComplete from '@tarekraafat/autocomplete.js'
import { sprintf } from 'sprintf-js'

// Connects to data-controller="layouts--header-search"
export default class extends Controller {
  static targets = ['type', 'typeTitle', 'typeList', 'typeInnerList', 'form', 'inputFreeword']

  initialize() {
    this.loaded()
  }

  connect() {
    this.events()
  }

  autoComplete(selector) {
    const element = document.querySelector(selector)

    if (!element) return

    const
      autoCompleteDesktop = new autoComplete({
      selector: () => {
        return element
      },
      data: {
        src: async () => {
          const
            typeCheckedValue = document.querySelector('input[name^=search_type]:checked').value,
            params = new URLSearchParams({
              type: typeCheckedValue,
              freeword: this.inputFreewordTarget.value,
            }).toString(),
            source = await fetch(`${searchAutocompleteUrl}?${params}`),
            data = await source.json()

          return data.freewords
        },
        cache: false,
      },
      resultsList: {
        element: (list, data) => {
          const info = document.createElement('p')

          if (data.results.length < 1) {
            info.innerHTML = sprintf(searchAutocompleteTranslations.results_html, data.matches.length, data.query)
            info.classList.add('active')
          } else {
            info.classList.contains('active') && info.classList.remove('active')
          }

          list.prepend(info)
        },
        noResults: true,
        maxResults: 10
      },
      resultItem: {
        highlight: true
      },
      events: {
        input: {
          focus: () => {
            autoCompleteDesktop.input.value.length && autoCompleteDesktop.start()
          },
          keydown: (event) => {
            switch (event.key) {
              case 'Enter':
                return

              case 'ArrowRight':
                autoCompleteDesktop.select()
                break

              case 'ArrowUp':
                autoCompleteDesktop.previous()
                break

              case 'ArrowDown':
                autoCompleteDesktop.next()
                break
            }
          }
        }
      }
    })

    element.addEventListener('selection', function(event) {
      element.value = event.detail.selection.value
    })
  }

  toggleType() {
    this.typeTarget.classList.toggle('active')
    this.typeListTarget.style.height =
      this.typeTarget.classList.contains('active') ? `${this.typeInnerListTarget.clientHeight}px` : null
  }

  clickType(event) {
    if (!event.target.control) return

    this.setTypeTitle(event.target.control.value)
    this.closeType()
  }

  closeType() {
    this.typeTarget.classList.remove('active')
    this.typeListTarget.style.height = null
  }

  setTypeTitle(value) {
    const checkedValue = value || document.querySelector('input[name^=search_type]:checked').value
    this.typeTitleTarget.textContent = searchTypeTranslations[checkedValue]
    this.formTarget.action = searchPaths[checkedValue]
    this.inputFreewordTarget.placeholder = searchPlaceholderTranslations[checkedValue]
  }

  clickOutsideType() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerTypeAround = that.typeTarget.contains(event.target),
        containerTypeVisible = window.getComputedStyle(that.typeTarget).visibility !== 'hidden',
        leftClicked = (event.which === 1)

      !containerTypeAround && containerTypeVisible && leftClicked && that.closeType()
    })
  }

  loaded() {
    this.setTypeTitle()
  }

  events() {
    this.clickOutsideType()
    this.autoComplete(".desktop .search .freeword input[name='freeword']")
    this.autoComplete(".banner.home .freeword input[name='freeword']")
  }
}
